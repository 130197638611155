<template>
  <div>
    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

    <h4 qid="maintenance-create-tp-title">
      {{$t('maintenance.third_parties.vendors.create.title')}}
    </h4>

    <div class="card">
      <div class="card-body">
        <div class="form-centered">
          <b-alert v-if="error.length"
                   show
                   variant="danger"
                   qid="create-tp-error"
          >
            {{ error }}
          </b-alert>
          <b-form @submit="formSubmit" qid="create-tp-form">
            <div class="form-container">

              <h5 class="mb-3">
                <label>{{ $t('maintenance.third_parties.create.name_description_section_label') }}</label>
              </h5>

              <b-form-group>
                <span class="align-middle mr-4">
                  <img v-bind:src="getAvatar"
                       class="rounded-circle"
                       width="120"
                       height="120"
                       qid="profile-photo"
                  >
                </span>
                <span class="align-middle">
                  <label class="btn btn-secondary cursor-pointer pl-2 pr-2">
                    <input type="file"
                           name="fileupload"
                           value="fileupload"
                           id="file"
                           @change="onFileChange"
                    >
                    <span>
                      <font-awesome-icon icon="cloud-upload-alt" />
                      {{ $t('maintenance.third_parties.vendors.upload_picture') }}
                    </span>
                  </label>
                </span>
              </b-form-group>

              <b-form-group>
                <label qid="create-tp-name-label">
                  {{ $t('maintenance.third_parties.create.name_label') }}
                </label>
                <span class="text-red">*</span>
                <b-form-input
                  qid="create-tp-form-name"
                  type="text"
                  v-model="tpForm.name"
                  required
                  :maxLength="255"
                  :placeholder="$t('maintenance.third_parties.create.name_placeholder')"
                ></b-form-input>
              </b-form-group>
              <b-form-group>
                <label qid="create-tp-description-label">
                  {{ $t('maintenance.third_parties.create.description_label') }}
                </label>
                <b-form-textarea
                  qid="create-tp-form-description"
                  v-model="tpForm.description"
                  rows="6"
                  max-rows="6"
                  :placeholder="$t('maintenance.third_parties.create.description_placeholder')"
                ></b-form-textarea>
              </b-form-group>

              <h5 class="mb-3">
                <label>{{ $t('maintenance.third_parties.create.address_section_label') }}</label>
              </h5>

              <b-form-group>
                <label qid="create-tp-name-label">
                  {{ $t('administration.clients.create.street_label') }}
                </label>
                <b-form-input
                  qid="create-client-form-street"
                  type="text"
                  v-model="tpForm.address"
                  :placeholder="$t('administration.clients.create.street_label')"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label qid="create-tp-name-label">
                  {{ $t('administration.clients.create.postcode_label') }}
                </label>
                <b-form-input
                  qid="create-client-form-postcode"
                  type="text"
                  v-model="tpForm.postal_code"
                  :placeholder="$t('administration.clients.create.postcode_label')"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label qid="create-tp-name-label">
                  {{ $t('administration.clients.create.city_label') }}
                </label>
                <b-form-input
                  qid="create-clients-form-city"
                  type="text"
                  v-model="tpForm.city"
                  :placeholder="$t('administration.clients.create.city_label')"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label qid="create-tp-name-label">
                  {{ $t('administration.clients.create.state_label') }}
                </label>
                <b-form-input
                  qid="create-clients-form-state"
                  type="text"
                  v-model="tpForm.state"
                  :placeholder="$t('administration.clients.create.state_label')"
                ></b-form-input>
              </b-form-group>

              <b-form-group>
                <label qid="create-tp-name-label">
                  {{ $t('administration.clients.create.country_label') }}
                </label>
                <multiselect
                    v-model="selectedCountry"
                    :options="countries"
                    :multiple="false"
                    track-by="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    :placeholder="$t('administration.clients.create.country_label')"
                    label="name"
                    qid="create-tp-countries-select"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <img v-bind:src="props.option.flag" height="13" class="align-baseline" />
                    <span class="mr-2">
                      {{ props.option.name }}
                    </span>
                  </template>
                  <template slot="option" slot-scope="props">
                    <img v-bind:src="props.option.flag" height="13" class="align-baseline" />
                    <span class="mr-2">
                      {{ props.option.name }}
                    </span>
                  </template>
                </multiselect>
              </b-form-group>

              <b-form-group>
                <label qid="create-tp-name-label">
                  {{ $t('administration.clients.create.cvr_label') }}
                </label>
                <b-form-input
                  qid="create-client-form-cvr"
                  type="text"
                  v-model="tpForm.company_number"
                  :placeholder="$t('administration.clients.create.cvr_label')"
                ></b-form-input>
              </b-form-group>

            </div>
            <div class="text-right">
              <b-button class="mt-3"
                        variant="secondary"
                        @click="goToList"
                        qid="create-tp-cancel-button"
              >
                {{ $t('maintenance.third_parties.create.cancel') }}
              </b-button>
              <b-button type="submit"
                        class="mt-3 ml-3"
                        variant="success"
                        :disabled="buttonDisabled"
                        qid="create-tp-submit-button"
              >
                <span v-if="buttonDisabled" class="mr-1">
                  <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
                </span>
                {{ $t('maintenance.third_parties.create.submit') }}
              </b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>

    <!-- Crop image modal -->
    <b-modal ref="crop-image" hide-footer size="lg">
      <div class="d-block text-center">
        <h4 qid="edit-profile-modal-title">
          {{ $t('Crop image') }}
        </h4>
      </div>
      <div class="form-container" style="max-height: 600px;">
        <img v-bind:src="imageToCrop"
             qid="profile-photo"
             id="crop"
        >
      </div>
      <div class="text-right">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideCropModal"
                  qid="edit-profile-cancel-button"
        >
          {{ $t('profile.password.cancel') }}
        </b-button>
        <b-button type="submit"
                  class="mt-3 ml-3"
                  variant="success"
                  id="crop-button"
                  :disabled="buttonDisabled"
                  qid="edit-profile-submit-button"
        >
          <span v-if="buttonDisabled" class="mr-1">
            <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
          </span>
          {{ $t('profile.password.submit') }}
        </b-button>
      </div>
    </b-modal>

  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js'
  import Avatar from '@/components/Avatar.vue';
  import Datepicker from 'vuejs-datepicker';

  import VueCropper from 'vue-cropperjs';
  import Cropper from 'cropperjs';
  import 'cropperjs/dist/cropper.css';

  export default {
    name: 'CreateVendor',
    data: function() {
      return {
        breadcrumbItems: [
          {
            text: this.$t('dashboard.title'),
            to: '/'
          },
          {
            text: this.$t('maintenance.third_parties.vendors.title'),
            to: '/third-parties'
          },
          {
            text: this.$t('maintenance.third_parties.vendors.create.title'),
            active: true
          }
        ],
        tpForm: {
          name: '',
          description: '',
          company_number: '',
          address: '',
          city: '',
          state: '',
          postal_code: '',
          country: ''
        },
        error: '',
        inputLink: '',
        selectedFiles: [],
        buttonDisabled: false,
        imageToCrop: '',
        avatar: '',
        avatarLarge: '',
        croppedBlob: {},
        countries: [],
        selectedCountry: []
      }
    },
    components: {
      Datepicker,
      Avatar
    },
    computed: {
      getClient() {
        return this.$store.state.client
      },
      getFiles() {
        return this.selectedFiles
      },
      getAvatar(){
        if (this.avatarLarge) {
          return this.avatarLarge;
        } else {
          return require('@/assets/tp-logo.png');
        }
      },
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {

      }
    },
    methods: {
      goToList() {
        this.$router.push('/third-parties')
      },
      resetFile() {
        let imagefile = document.querySelector('#file');
        imagefile.value = '';
      },
      showCropModal() {
        let self = this

        this.$refs['crop-image'].show()
        this.buttonDisabled = false;

        setTimeout(function(){
          const image = document.getElementById('crop');
          const cropper = new Cropper(image, {
            aspectRatio: 1,
            viewMode: 2,
            crop(event) {},
          });

          document.getElementById('crop-button').addEventListener('click', function(){
            cropper.getCroppedCanvas().toBlob(function (blob) {

              self.avatar = URL.createObjectURL(blob);
              self.avatarLarge = URL.createObjectURL(blob);
              self.imageToCrop = URL.createObjectURL(blob);
              self.croppedBlob = blob

              self.$refs['crop-image'].hide()

            });
          })
        }, 500);
      },
      hideCropModal() {
        this.imageToCrop = ''
        this.avatar = ''
        this.avatarLarge = ''
        this.croppedBlob = ''
        this.$refs['crop-image'].hide()
      },
      onFileChange(file) {
        this.tpForm.file = file;
        const avatar = file.target.files[0];
        this.avatar = URL.createObjectURL(avatar);
        this.avatarLarge = URL.createObjectURL(avatar);
        this.imageToCrop = URL.createObjectURL(avatar);

        this.showCropModal()
      },
      handleLink() {
        // If there's a link in input, add it to links array
        if (this.inputLink !== '') {
          if (!this.inputLink.startsWith('http')) {
            this.inputLink = 'http://' + this.inputLink
          }
          this.tpForm.links.push(this.inputLink)
          // Reset link input form
          this.inputLink = ''
        }
      },
      removeLinkItem(index) {
        // Remove link item from the links array
        this.tpForm.links.splice(index, 1);
      },
      removeUser(index) {
        let selectedItem = this.selectedUsers[index]
        selectedItem.owner = false;
        this.$set(this.selectedUsers, index, selectedItem)
        // Remove item from selected users array
        this.selectedUsers.splice(index, 1);
      },
      removeUserFromDropdown(item) {
        item.owner = false;
      },
      setOwner(index) {
        let selectedItem = this.selectedUsers[index]
        _.forEach(this.selectedUsers, function (item) {
          item.owner = false;
        })
        selectedItem.owner = true;
        this.$set(this.selectedUsers, index, selectedItem)
      },
      formSubmit(evt) {
        var moment = require('moment');
        let formData = new FormData();
        let sizeError = false;
        let selectedOwner = false;

        // Call handle link function to gather link from link input
        this.handleLink()

        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        evt.preventDefault();

        if (this.croppedBlob.size > 10485760) {
          sizeError = true;
        }

        if (this.selectedCountry && this.selectedCountry.name) {
          formData.append("country", this.selectedCountry.name)
        }

        formData.append("logo", this.croppedBlob);
        formData.append("name", this.tpForm.name);
        formData.append("description", this.tpForm.description);
        formData.append("company_number", this.tpForm.company_number);
        formData.append("address", this.tpForm.address);
        formData.append("city", this.tpForm.city);
        formData.append("state", this.tpForm.state);
        formData.append("postal_code", this.tpForm.postal_code);
        formData.append("client", this.getClient.slug);


        if (sizeError) {
          this.error = this.$t('system.file_size')
        } else {
          this.buttonDisabled = true;
          // Post FormData object to third parties API
          piincHttp.post('/vendors', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function(response) {
            self.$toastr('success', self.$t('maintenance.third_parties.vendors.create.success'))
            self.buttonDisabled = false;
            self.$router.push({ name: 'contracts.third_parties.view', params: { slug: response.data.slug }})
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
              if (error.data.errors['files.0']) {
                self.error += ' ' + error.data.errors['files.0'][0]
              }
            }
          })
        }

      },
      loadCountries() {
        let self = this;
        piincHttp.get('countries-with-flag').then(function(response) {
          self.countries = response.data
        }, function() {});
      },
    },
    created () {
      if (this.getClient && this.getClient.slug) {

      }
      this.loadCountries()
    }
  }
</script>
